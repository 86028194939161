import { defineStore } from 'pinia'
import get from 'lodash/get'

import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'
import { useDocumentsStore } from '~/store/documents'
import { useRootStore } from './root'
import { MBOX_DETAILS } from '../libs/adobe-target';
import { useAppAnalyticsStore } from './app-analytics';
import appNameFromRoute from '~/libs/app-name-from-route'
import {useAffiliateCustomizationStore} from './affiliate-customization';


export const useSunriseFinicityStore = defineStore('sunriseFinicity', () => {
  const documentsStore = useDocumentsStore()
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  const appAnalyticsStore = useAppAnalyticsStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const currentRoute = useRoute()
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  async function _getBanks(borrowerId) {
    return $axios.get(`${env('apiUrl')}/banking/borrower/${borrowerId}/get-banks`)
      .then(response => {
        return get(response, 'data.data') || []
      })
      .catch(() => {
        return null
      })
  }

  async function _getBankStatement(account, monthsBack, borrowerId) {
    const payload = JSON.stringify({
      ...account,
      months: monthsBack,
    })
    return $axios.post(`${env('apiUrl')}/banking/borrower/${borrowerId}/pull-bank-statement`, payload, { headers: { 'Content-Type': 'application/json' } })
      .then(response => {
        return response || null
      })
  }

  async function _getBankingLinkToken(borrowerId) {
    return $axios.post(`${env('apiUrl')}/banking/borrower/${borrowerId}/bank-link-token`, {})
      .then(response => {
        return get(response, 'data.data', null)
      })
      .catch(() => {
        return null
      })
  }

  async function _getBankingFixToken(bankId, borrowerId) {
    return $axios.post(`${env('apiUrl')}/banking/borrower/${borrowerId}/bank-fix-token`, {
        bankId
      })
      .then(response => {
        return get(response, 'data.data', null)
      })
      .catch(() => {
        return null
      })
  }

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const banks = ref([])
  const syncBankName = ref(null)
  const syncProgress = ref(null)
  const failedIndex = ref(0)
  const failed = ref(false)
  const failReason = ref(null)
  const syncingAccountPayload = ref(null)
  const pullingBankStatement = ref(false)
  const selectedAccountId = ref(null);
  const isDocumentsFastFlowEnabled = ref(false)
  const hasMultipleBankAccounts = ref(false)
  const sunriseApiError = ref(null)
  const finicityConnectActive = ref(false)
  const finicityConnectLoading = ref(false)
  const connectBankAccountVisible = ref(false)
  const connectBankAccountSuccessful = ref(null)
  const documentsBreakout = ref(true)
  const embeddedFinicityEventListener = ref(null)
  const newlyConnectedAccountsCount = ref(0)
  const accountConnectionNotFound = ref(false)
  const unsupportedCheckingAccounts = ref(false)
  const hasMultipleSyncFailures = ref(false)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const hasBanks = computed(() => {
    return banks.value.length > 0
  })

  const bankStatementsUploadedOrBanksConnected = computed(() => {
    return banks.value.length > 0 || documentsStore.hasOfTypes([{ type: 'bankStatement', min: 3 }])
  })

  const bankStatementsUploaded = computed(() => {
    return documentsStore.documents.filter(
      (doc) => doc.category === 'bankStatement'
    )
  })

  const finicityUploads = computed(() => {
    if (!bankStatementsUploaded.value) {
      return []
    } else {
      return bankStatementsUploaded.value.filter(
        (doc) => doc.uploadSource === 'finicity'
      )
    }
  })

  const finicityActive = computed(() => {
    return finicityConnectActive.value || finicityConnectLoading.value
  })

  const allBankAccounts = computed(() => {
    let flattenedAccounts = []
    banks.value.forEach((_bank) => {
      if (_bank.accounts) {
        const accounts = _bank.accounts.reduce((acc, account) => {
          return [...acc, account]
        }, [])
        flattenedAccounts = [...flattenedAccounts, ...accounts]
      }
    })

    return flattenedAccounts
  })

  const cashInAccounts = computed(() => {
    if (!hasBanks.value) {
      return 0
    }
    return allBankAccounts.value
      .filter((account) => ['checking', 'savings'].includes(account.type))
      .reduce((sum, account) => sum + account.balance, 0)
  })

  const checkingAccounts = computed(() => {
    return banks.value.reduce((acc, curr) => {
      return acc.concat(curr.accounts.filter(account => account.type === 'checking' &&  (curr?.providerStatements ?? true) === true))
    }, [])
  })

  const unableToPullStatements = computed(() => unsupportedCheckingAccounts.value === true || checkingAccounts.value.length === 0)

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  ! - - refs must be exported and cannot be private to the store as it may break SSR
  ! - - https://pinia.vuejs.org/core-concepts/#Setup-Stores5e
*/

  async function bankStatementPullRequest(accountId = null, delay = 0) {
    const borrowerId = borrowerStore.borrowerId
    const payload = accountId !== null ? JSON.stringify({accountId}) : null;
    const makeRequest = (ms) => new Promise((resolve) => {
      accountConnectionNotFound.value = false
      hasMultipleBankAccounts.value = false
      unsupportedCheckingAccounts.value = false

      setTimeout(async () => {
        const response = await $axios.post(`${env('apiUrl')}/banking/borrower/${borrowerId}/statements`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          return response
        }).catch((error) => {
          const statusError = error?.response?.status

          if (statusError === 404) {
            accountConnectionNotFound.value = true
          }

          if (statusError === 412) {
            unsupportedCheckingAccounts.value = true
          }

          if (statusError === 409) {
            const data = error?.response?.data?.data

            if (!data) {
              log.error('Error - no banks in response', {
                error,
                borrowerId,
                accountId
              })
              return
            }

            unsupportedCheckingAccounts.value = !_hasSupportedCheckingAccount(data)
            hasMultipleBankAccounts.value = true
            banks.value = data
            return
          }
          log.warning('Error making bank statements pull request', {
            error,
            borrowerId,
            accountId
          })
        })

        resolve(response)
      }, ms)
    })

    const response = await makeRequest(delay)

    if (accountConnectionNotFound.value){
      return await makeRequest(2000)
    }

    return response
  }

  async function setDocumentsFastFlow() {
    const appName = appNameFromRoute(currentRoute)

    if (!appName || appName !== 'marketplace' || !affiliateCustomizationStore.isLendioTenant) {
      isDocumentsFastFlowEnabled.value = false
      return
    }

    const targetRes = await appAnalyticsStore.checkSingleTargetOffer({
      mbox: MBOX_DETAILS.DOCUMENTS_FAST_FLOW.NAME,
      searchTerm: 'documentsFastFlow',
      params: {
        email: rootStore?.authUser?.email,
      },
    })

    if (targetRes) {
      const borrowerId = borrowerStore.borrowerId
      await appAnalyticsStore.fsTrackEvent({ name: 'Documents Fast Flow', properties: { borrowerId } })
      isDocumentsFastFlowEnabled.value = true
    }
  }

  function setSunriseApiError(value) {
    if (process.client && window && window.dreams) {
      window.dreams.setEvent('bank_connect_error', 'true')
    }

    if (value === 'resetError') {
      sunriseApiError.value = null
      return
    }
    sunriseApiError.value =
      'There has been an error connecting you with our sync services. Please upload bank statements manually.'
  }

  function clearFailedPull() {
    failedIndex.value = 0
    failReason.value = null
    failed.value = false
  }

  function setFailedPull(value = {}) {
    let index, _failReason
    if (value instanceof Object) {
      index = value.index
      _failReason = value.failReason
    } else {
      index = value
      _failReason = null
    }
    failedIndex.value = index
    failReason.value = _failReason
    failed.value = true
  }

  async function getBanks() {
    if (isDocumentsFastFlowEnabled.value === true && hasBanks.value === true) {
      return
    }

    const borrowerId = borrowerStore.borrowerId

    const _banks = await _getBanks(borrowerId)
    if (_banks === null) {
      this.setSunriseApiError()
    }
    banks.value = _banks || []
  }

  async function openFixModal(bankId = null) {
    const borrowerId = borrowerStore.borrowerId
    bankId = bankId || get(syncingAccountPayload.value, 'bankId')
    if (!bankId) {
      console.error('BankId not found for Finicity account fix modal')
      this.clearFailedPull()
      return
    }
    const modalUrl = await _getBankingFixToken(
      bankId,
      borrowerId
    )
    if (!modalUrl) {
      console.error('Fix modal url not retrieved correctly')
      this.clearFailedPull()
      return
    }
    return await this.openFinicityModal({ modalUrl })
  }

  async function openFinicityModal({ modalUrl = null } = {}) {
    connectBankAccountSuccessful.value = false
    finicityConnectLoading.value = true
    this.setSunriseApiError('resetError') // Reset the error message since we're retrying to connect
    const borrowerId = borrowerStore.borrowerId
    const token =
      modalUrl ||
      (await _getBankingLinkToken(borrowerId))
    if (!token) {
      finicityConnectLoading.value = false
      connectBankAccountSuccessful.value = false
      await this.setSunriseApiError()
      return
    }

    const promise = new Promise((resolve, reject) => {
      if (!useRootStore().isEmbedded) {
          finicityConnectActive.value = true
          window.finicityConnect.launch(token, {
            selector: '#sunrise-finicity-container',
            overlay: 'rgba(255,255,255, 0)',
            success: (event) => {
              resolve('success')
              this.finicitySuccess()
            },
            cancel: (event) => {
              resolve('cancel')
              this.finicityCancel()
            },
            error: (error) => {
              resolve('error')
              this.finicityError()
            },
            loaded: () => {},
            route: (event) => {
              _handleFinicityRouteEvent(event)
            },
            user: (event) => {
             _handleFinicityUserEvent(event)
            }
          })
        } else {
          // EMBEDDED ONLY FINICITY LOGIC
          // in the embedded use case, the embedded app must handle the launching of finicity
          // in order to avoid issues with loading the finicity iframe within another iframe
          window.parent.postMessage({
            source: 'BP',
            method: 'showFinicityModal',
            action: 'showFinicityModal',
            data: JSON.parse(JSON.stringify({token}))
          }, '*')
          embeddedFinicityEventListener.value = (event) => {
            if (event.data.source === 'Embedded App' && event.data.action.startsWith('FINICITY')) {
              switch (event.data.action) {
                case 'FINICITY_SUCCESS':
                  this.finicitySuccess()
                  resolve('success')
                  break
                case 'FINICITY_ERROR':
                  this.finicityError()
                  resolve('error')
                  break
                case 'FINICITY_CANCEL':
                  this.finicityCancel()
                  resolve('cancel')
                  break
                default:
                  this.finicityCancel()
                  resolve('cancel')
                  break;
              }
            }
          }
          window.addEventListener('message', embeddedFinicityEventListener.value)
        }
      })
      return await promise
    }

  function finicitySuccess() {
    if (!isDocumentsFastFlowEnabled.value) {
      this.getBanks()
    }
    finicityConnectActive.value = false
    connectBankAccountSuccessful.value = true
    if (window.dreams) {
      window.dreams.setEvent('bank_connect_success', 'true')
    }
    finicityConnectLoading.value = false
  }

  function finicityError() {
    finicityConnectActive.value = false
    // bank_connect_error set to true in mutation below.
    this.setSunriseApiError()
    finicityConnectLoading.value = false
  }

  function finicityCancel() {
    finicityConnectActive.value = false
    if (window.dreams) {
      window.dreams.setEvent('bank_connect_cancel', 'true')
    }
    finicityConnectLoading.value = false
  }


  async function getBankStatements(payload = null) {
    // A payload implies this is a fresh bank statement request
    if (payload) {
      this.clearFailedPull()
    }
    if (!payload && !syncingAccountPayload.value) {
      return undefined
    } else if (!payload) {
      payload = syncingAccountPayload.value
    } else {
      syncingAccountPayload.value = payload
    }

    const borrowerId = borrowerStore.borrowerId

    syncProgress.value = 0
    pullingBankStatement.value = true
    const { bankId, selectedAccountId, monthsBack } = payload

    const selectedBank = banks.value.find((_bank) => _bank.bankId === bankId)
    if (!selectedBank) {
      return 'failed'
    }
    syncBankName.value = selectedBank.bankName

    const account = selectedBank.accounts.find((account) => account.accountId === selectedAccountId)

    if (!account) {
      return 'failed'
    }

    let documentModels = []
    const progressBase = monthsBack * 2
    let progressValue = failedIndex.value ? failedIndex.value * 2 - 1 : 0
    for (let index = failedIndex.value || 1; index <= monthsBack; index++) {
      let response
      if (!failedIndex.value) {
        progressValue++
      }
      syncProgress.value = Math.round((progressValue / progressBase) * 100)
      try {
        response = await _getBankStatement(
          account,
          index,
          borrowerId
        )
        // Finicity Auth retry
        if (get(response, 'status') === 203) {
          this.clearFailedPull()
          break
        }
      } catch (error) {
        if (/mfa required/i.test(get(error.response, 'data.errors[0].message'))) {
          this.setFailedPull({ index, failReason: 'mfa' })
        } else {
          console.error('Error retrieving bank statement for month, ' + index, error)
          this.setFailedPull({ index })
        }
        break
      }
      const documentModel = get(response, 'data.data', null)
      documentsStore.addDocument(documentModel, 'finicity')
      progressValue++
      syncProgress.value = Math.round((progressValue / progressBase) * 100)
      documentModels.push(documentModel)
    }
    pullingBankStatement.value = false
  }

  function setSelectedAccountId(account) {
    selectedAccountId.value = account
  }

  function _handleFinicityRouteEvent(event) {
    const screen = event?.data?.screen
    if (screen) {
      appAnalyticsStore.fsTrackEvent({name: 'Finicity Modal - Route', properties: {screen}})
    }
  }
  function _handleFinicityUserEvent(event) {
    const action = event?.data?.action
    const errorCode = event?.data?.errorCode

    if (!action) return
    appAnalyticsStore.fsTrackEvent({ name: 'Finicity Modal - User Action', properties: { action, errorCode } })

    if (action === 'AddAccountsSuccess') {
      newlyConnectedAccountsCount.value++
    }

    if (action === 'RemoveConnectedInstitutionSuccess' && newlyConnectedAccountsCount.value > 0) {
      newlyConnectedAccountsCount.value--
    }

    if (action === 'GetInstitutionsSuccess' && event?.data?.searchTerm !== undefined) {
      const searchTerm = event.data.searchTerm
      appAnalyticsStore.fsTrackEvent({ name: 'Finicity Modal - Search Term', properties: { searchTerm } })
    }
  }

  function _hasSupportedCheckingAccount(banks) {
    for (const bank of banks) {
      if (!Array.isArray(bank.accounts)) {
        continue;
      }
      for (const account of bank.accounts) {
        if (account.type === 'checking' && bank?.providerStatements !== false) {
          return true;
        }
      }
    }
    return false;
  }

  return {
    //STATE
    banks,
    syncBankName,
    syncProgress,
    failed,
    failedIndex,
    failReason,
    syncingAccountPayload,
    pullingBankStatement,
    sunriseApiError,
    isDocumentsFastFlowEnabled,
    hasMultipleBankAccounts,
    finicityConnectActive,
    finicityConnectLoading,
    connectBankAccountVisible,
    connectBankAccountSuccessful,
    documentsBreakout,
    selectedAccountId,
    newlyConnectedAccountsCount,
    accountConnectionNotFound,
    unableToPullStatements,
    unsupportedCheckingAccounts,
    hasMultipleSyncFailures,

    //GETTERS
    hasBanks,
    bankStatementsUploadedOrBanksConnected,
    bankStatementsUploaded,
    finicityUploads,
    finicityActive,
    allBankAccounts,
    cashInAccounts,
    checkingAccounts,

    //ACTION
    bankStatementPullRequest,
    setDocumentsFastFlow,
    clearFailedPull,
    finicityCancel,
    finicityError,
    finicitySuccess,
    getBanks,
    getBankStatements,
    openFinicityModal,
    openFixModal,
    setFailedPull,
    setSunriseApiError,
    setSelectedAccountId,
  }
})
