<template>
  <div class="d-flex flex-grow-1 justify-content-center align-items-center">
    <Card ref="login">
      <template #title>
        Sign in to your account.
      </template>
      <template #main>
        <div class="form-floating">
          <input
            id="email"
            name="email"
            class="form-control mx-auto"
            type="email"
            :disabled="submitting"
            :placeholder="'Email'"
            :required="true"
            v-model="email"
            :class="{ 'is-invalid': safeGet(v$, 'email.$invalid') && safeGet(v$, 'email.$dirty') }"
            @change="v$.email.$touch()"
            @blur="v$.email.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="email"
          />
          <label for="email"><span>Email</span></label>
          <small class="invalid-feedback" v-if="safeGet(v$, 'email.$error')">
            <fa-icon :icon="['fal', 'circle-exclamation']"></fa-icon>
            {{ safeGet(v$, 'email.$errors')[0].$message }}
          </small>
        </div>
        <div class="mb-3"></div>
        <div class="form-floating">
          <input
            id="password"
            name="password"
            class="form-control mx-auto"
            :class="{ 'is-invalid': safeGet(v$, 'password.$invalid') && safeGet(v$, 'password.$dirty') }"
            :type="showPassword ? 'text' : 'password'"
            :disabled="submitting"
            :aria-label="'Password'"
            :placeholder="'Password'"
            :required="true"
            v-model="password"
            @change="v$.password.$touch()"
            @input="v$.password.$touch()"
            @blur="v$.password.$touch()"
            @keyup.enter="validateAndSubmit"
            autocomplete="current-password"
          />
          <label for="password"><span>Password</span></label>
          <span class="text-tooltip-icon" @click="toggleViewPassword()">
            <fa-icon v-if="showPassword" :icon="['fal', 'eye-slash']"></fa-icon>
            <fa-icon v-else :icon="['fal', 'eye']"></fa-icon>
          </span>
          <small class="invalid-feedback" v-if="safeGet(v$, 'password.$error')">
            <fa-icon :icon="['fal', 'circle-exclamation']"></fa-icon>
            {{ safeGet(v$, 'password.$errors')[0].$message }}
          </small>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-4 row">
          <div class="col-12 col-sm-6">
            <div class="form-check form-switch">
              <input
                :checked="remember"
                name="rememberMe"
                :disabled="submitting"
                @change="toggleRememeberMe()"
                class="form-check-input"
                type="checkbox"
                id="rememberMe">
              <label class="form-check-label" for="rememberMe">Remember me</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 forgot-password">
            <router-link class="fw-bold" :to="identityStore.forgotPasswordUrl">
              Forgot password?
            </router-link>
          </div>
        </div>
      </template>
      <template #actions>
        <div class="d-flex justify-content-end">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="submitting || v$.$invalid || !v$.$dirty"
            @click="validateAndSubmit">
            <ButtonLoadingSpinner v-if="submitting"></ButtonLoadingSpinner>Sign in
          </button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { localStorageService } from '~/libs/local-storage.service'
import { required, email } from '@vuelidate/validators'
import { useIdentityStore } from '~/store/identity'
import { useRoute, useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import ButtonLoadingSpinner from '../marketplace/subComponents/ButtonLoadingSpinner'
import Card from './Card.vue'
import get from 'lodash/get'
import LendioBootstrapTooltip from '../bootstrap/LendioBootstrapTooltip'

export default {
  name: 'IdentityLogin',
  components: [
    ButtonLoadingSpinner,
    Card,
    LendioBootstrapTooltip,
  ],
  async setup() {
    return {
      v$: useVuelidate(),
      safeGet: get,
      identityStore: useIdentityStore(),
      route: useRoute(),
      router: useRouter(),
      rememberedIdentity: localStorageService.getItem('rememberedIdentity'),
    }
  },
  data() {
    return {
      email: '',
      password: '',
      submitting: false,
      remember: false,
      showPassword: false,
    }
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
    }
  },
  async mounted() {
    if (this.route?.query?.success) {
      this.showSuccessAlert(true, this.route.query.success)
      this.router.replace({ query: { ...this.route.query, success: undefined } })
    } else if (this.route?.query?.error) {
      this.showErrorAlert(true, this.route.query.error)
      this.router.replace({ query: { ...this.route.query, error: undefined } })
    } else {
      this.closeAlert()
    }
    if (this.route?.query?.e) {
      this.email = this.route?.query?.e
      this.v$.email.$touch()
    } else if (this.rememberedIdentity) {
      this.email = this.rememberedIdentity
      this.v$.email.$touch()
      this.remember = true
    }
  },
  methods: {
    async validateAndSubmit() {
      this.closeAlert()
      this.v$?.$touch()
      if (this.v$.$invalid) return
      this.submitting = true
      await this.login()
    },
    async login() {
      try {
        await this.identityStore.login(this.email, this.password)
        if (!this.remember) {
          localStorageService.removeItem('rememberedIdentity')
        } else {
          localStorageService.setItem('rememberedIdentity', this.email)
        }
      } catch (error) {
        const errors = get(error, 'response.data.errors') ?? ['Failed to login. Please try again.']
        let errorMessage = errors[0]
        if (errorMessage.includes('locked')) {
          errorMessage = this.sanitize(errorMessage) + `. Please wait and try again later or <a href="/bp${this.identityStore.forgotPasswordUrl}">reset your password.</a>`
        }
        this.showErrorAlert(false, errorMessage)
        this.password = ''
        this.submitting = false
      }
    },
    toggleViewPassword() {
      this.showPassword = !this.showPassword
    },
    toggleRememeberMe() {
      this.remember = !this.remember
    },
    showErrorAlert (untrusted, message) {
      if (this.$refs?.login?.$refs?.alert) {
        this.$refs.login.$refs.alert.openDanger({
          content: message,
          untrusted,
        })
      }
    },
    showSuccessAlert (untrusted, message) {
      if (this.$refs?.login?.$refs?.alert) {
        this.$refs.login.$refs.alert.openSuccess({
          content: message,
          untrusted,
        })
      }
    },
    closeAlert() {
      if (this.$refs?.login?.$refs?.alert) {
        this.$refs.login.$refs.alert.closeAlert()
      }
    },
    sanitize(input) {
      const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
      };
      return input.replace(/[&<>"']/g, (m) => { return map[m]; });
    },
  }
}
</script>

<style lang="scss" scoped>
.forgot-password {
  margin-top: 10px;
}
@media screen and (min-width: 576px) {
  .forgot-password {
    margin-top: 0;
    text-align: right;
  }
}
</style>
