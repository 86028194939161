import { defineStore } from 'pinia'
import {ref, computed } from 'vue'
import get from 'lodash/get'

import maxBy from 'lodash/maxBy.js'
import some from 'lodash/some.js'
import intersection from 'lodash/intersection.js'
import includes from 'lodash/includes.js'
import { getTime } from 'date-fns'
import { addMonths } from 'date-fns'
import { isAfter } from 'date-fns'
import { startOfDay } from 'date-fns'
import { parseISO } from 'date-fns'
import { isValid as isValidDate }  from 'date-fns'
import { isAfter as isDateAfter } from 'date-fns'
import { isEqual as isDateEqual } from 'date-fns'
import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'
import { useApprovalsStore } from '~/store/approvals'
import { useExperimentsStore } from '~/store/experiments'
import { useOptInsStore } from '~/store/opt-ins'
import { useCreditCardsStore } from '~/store/credit-cards'
import { useProgressStore } from '~/store/progress'
import { useOfferOptionsStore } from '~/store/offer-options'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useOffersStore } from '~/store/offers'
import { PPP_LOAN_TYPES } from '~/libs/portal-constants'
import { getLenderSubmissionDealState } from '~/libs/deal-status'
import OfferTotal from '~/libs/offer-total'
import getEstimatedLoanPayments from '~/libs/estimated-loan-payments'
import { sortOptions } from '~/libs/sort-options'
import { compatibleDate } from '~/libs/date-helpers'
import { sbaLoanCategoryTypes } from '~/libs/loan-product-info'
import { IL_CLIENT_LENDERS_IDS } from '~/libs/offer-helper'

/**
 * @typedef {Object} Deal
 * @prop {any} [Deal.acceptedOffer]
 * @prop {any[]} [Deal.applications]
 * @prop {string} [Deal.autoSubmitType]
 * @prop {string} [Deal.categoryShortName]
 * @prop {any} [Deal.contract]
 * @prop {string} [Deal.created]
 * @prop {string} [Deal.dateClosed]
 * @prop {string} [Deal.deleted]
 * @prop {any[]} [Deal.documents]
 * @prop {number} Deal.id
 * @prop {string} [Deal.lastActivity]
 * @prop {string} [Deal.lenderDomain]
 * @prop {number} [Deal.lenderId]
 * @prop {string} [Deal.lenderName]
 * @prop {number} [Deal.lenderPublicPhone]
 * @prop {string} [Deal.loanProductCategoryType]
 * @prop {number} [Deal.loanProductId]
 * @prop {string} [Deal.loanProductOfferType]
 * @prop {string} [Deal.loanType]
 * @prop {number} [Deal.maxOffer]
 * @prop {any} [Deal.offers]
 * @prop {any} [Deal.progress]
 * @prop {number} [Deal.sbaLoanNumber]
 * @prop {string} [Deal.stage]
 * @prop {string} [Deal.status]
 * @prop {string} [Deal.type]
 */
/**
 * @typedef {Object} DealRef
 * @property {Deal[]} DealRef.value
 */

export const useDealsStore = defineStore('deals', () => {

  const approvalsStore = useApprovalsStore()
  const borrowerStore = useBorrowerStore()
  const experimentsStore = useExperimentsStore()
  const optInsStore = useOptInsStore()
  const creditCardsStore = useCreditCardsStore()
  const progressStore = useProgressStore()
  const offerOptionsStore = useOfferOptionsStore()
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const offersStore = useOffersStore()
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

/** @type {DealRef} */
  const deals = ref([])
  const eligibleOffers = ref([])
  const hasBlueVineDeal = ref(true)
  const visibleDeals = ref([])
  const loaded = ref(false)
  const eligibleOffersLoaded = ref(false)
  const possibleEligibleOffers = ref([])

  /*
     ██████  ███████ ████████ ████████ ███████ ██████  ███████
    ██       ██         ██       ██    ██      ██   ██ ██
    ██   ███ █████      ██       ██    █████   ██████  ███████
    ██    ██ ██         ██       ██    ██      ██   ██      ██
     ██████  ███████    ██       ██    ███████ ██   ██ ███████
    GETTERS
  */
    // The current approval.
    const approval = computed(() => {
      return get(approvalsStore, 'currentApproval')
    })

    const hasActiveDeals = computed(() => {
      return deals.value.some((_deal) => _deal.stage !== 'inactive')
    })

    const hasFundedDeals = computed(() => {
      return deals.value.some((_deal) => _deal.stage === 'funded')
    })

    const hasDealInClosingStatus = computed(() => {
      return deals.value.some((_deal) => ['contractRequested', 'contractIn', 'contractOut', 'funding'].includes(_deal.status))
    })

    const awaitingResponseDeals = computed(() => {
      return deals.value.filter((_deal) => _deal.status === 'awaitingResponse' && ['optin', 'ppp'].includes(_deal.autoSubmitType) === false)
    })

    const hasFundedTierOneDeals = computed(() => {
      return deals.value.some((_deal) => {
        return _deal.stage === 'funded' &&
          _deal.loanProductOfferType === 'tier-1' &&
          ['optin', 'ppp'].includes(_deal.autoSubmitType) === false;
      })
    })

    const hasActiveTierOneDeals = computed(() => {
      return deals.value.some((_deal) => {
        return !['inactive', 'funded'].includes(_deal.stage) &&
          _deal.loanProductOfferType === 'tier-1' &&
          ['optin', 'ppp'].includes(_deal.autoSubmitType) === false
      })
    })

    const sortedDeals = computed(() => {
      return (deals.value || []).slice().sort((a, b) => {
        return (new Date(compatibleDate(b.created)).getTime()) - (new Date(compatibleDate(a.created)).getTime())
      })
    })

    // The current offer approval output based on the current change.
    // See the output() getter in the approvals store.
    const output = computed(() => {
      const _approval = approval.value
      const currentChange = approvalsStore.currentChange
      if (!currentChange) {
        return false
      }
      const change = currentChange(approval)

      // The output getter requires the currentApproval and currentChange.
      // return rootGetters.approvals.output(approval, change)
      return approvalsStore.buildOutput(_approval, change)
    })
    /**
     *  Get the number of deals by type.
     *
     * @returns {( type: string ) => number} - Returns a function
     *          that returns the count of deals that match the type passed.
     */
    const dealsCountByCategoryType = computed(() => {
      return (type) => {
        return (
          deals.value.filter((_deal) => _deal.loanProductCategoryType === type)
            .length || 0
        )
      }
    })

    const dealWithAcceptedOffer = computed(() => {
      return deals.value.find(
        (_deal) => _deal.acceptedOffer && _deal.status !== 'dead'
      )
    })

    const activeDeal = computed(() => {
      const activeDeals = sortedDeals.value.filter((deal) => {
        return ['optin', 'ppp'].includes(deal.autoSubmitType) === false &&
          deal.loanProductOfferType === 'tier-1' &&
          deal.stage !== 'inactive' &&
          ['declined', 'offerDeclined'].includes(deal.status) === false
      });

      if (!activeDeals.length) {
        return null;
      }
      let activeDeal = activeDeals.at(0)
      if (activeDeal?.stage !== 'funded') {
        // get recent offer close to the final process.
        const dealsInProgress = []
        for (let deal of activeDeals) {
          if (deal?.stage === 'funded') {
            break;
          }
          dealsInProgress.push(deal)
        }
        dealsInProgress.sort((a, b) => {
          const priorities = ['offerReceived', 'offerToBorrower', 'offerAccepted', 'contractRequested', 'contractOut', 'contractIn', 'funding']
          const getPriority = (status) => priorities.indexOf(status) + 1
          const getStatus = (deal) => (
            deal?.acceptedOffer
            && ['awaitingResponse', 'pendingFundingManager', 'offerReceived', 'offerToBorrower'].includes(deal?.status) ? 'offerAccepted' : deal?.status
          )
          return getPriority(getStatus(b)) - getPriority(getStatus(a))
        })
        activeDeal = dealsInProgress.at(0)
      }
      if (['appInProgress', 'appComplete'].includes(activeDeal?.status)) {
        return null;
      }
      if (activeDeal?.status === 'awaitingResponse' && !activeDeal?.acceptedOffer) {
        return null;
      }

      const recentRenewalApp = progressStore.renewalApps?.length ? progressStore.renewalApps.at(0) : null
      const renewalAppDate = progressStore.getAppDate(recentRenewalApp)

      if (activeDeal?.stage === 'funded' && renewalAppDate && renewalAppDate > new Date(compatibleDate(activeDeal.created))) {
        return null;
      }

      return activeDeal;
    })

    const renewalEligibilityPercent = computed(() => {
      return (offer) => {
        const DEFAULT_RENEWAL_PERCENTAGE = 100

        return offer?.lenderRenewalEligibility || DEFAULT_RENEWAL_PERCENTAGE
      }
    })

    const latestBorrowerRenewalEstDealId = computed(() => {
      const dealsWithRenewalSupport = (sortedDeals.value || []).filter((deal) => {
        return (
          (!deal.autoSubmitType || ['qualified', 'vamp', 'decision_for_approval', 'decision_for_preapproval'].includes(deal.autoSubmitType)) &&
          deal.loanProductOfferType === "tier-1" &&
          deal.stage === "funded" &&
          !/^(sba|erc|loc)/ig.test(deal.loanProductCategoryType || '')
        );
      });

      const estimatedRenewalEligibility = borrowerStore.estimatedRenewalEligibility && isValidDate(parseISO(borrowerStore.estimatedRenewalEligibility))
        ? parseISO(borrowerStore.estimatedRenewalEligibility)
        : null

      if (!estimatedRenewalEligibility) {
        return dealsWithRenewalSupport?.at(0)?.id ?? null
      }

      return dealsWithRenewalSupport.find((deal) => {
        const fundedDate = deal.dateClosed && isValidDate(new Date(compatibleDate(deal.dateClosed)))
          ? new Date(compatibleDate(deal.dateClosed))
          : null

        if (!fundedDate) {
          return false
        }
        return isDateAfter(estimatedRenewalEligibility, fundedDate)
      })?.id ?? null
    })

    const renewEligibleDate = computed(() => {
      //d = deal
      return (_deal, _acceptedOffer) => {
        if (!_deal || _deal.stage !== 'funded') return null
        const offer = _acceptedOffer || _deal.acceptedOffer
        if (!offer) return null

        if (PPP_LOAN_TYPES.includes(_deal?.loanProductCategoryType)) {
          return null
        }

        const fundedDate = _deal.dateClosed && isValidDate(new Date(compatibleDate(_deal.dateClosed)))
          ? new Date(compatibleDate(_deal.dateClosed))
          : null

        if (!fundedDate) return null

        const estimatedRenewalEligibility = borrowerStore.estimatedRenewalEligibility && isValidDate(parseISO(borrowerStore.estimatedRenewalEligibility))
          ? parseISO(borrowerStore.estimatedRenewalEligibility)
          : null

        if (estimatedRenewalEligibility && latestBorrowerRenewalEstDealId.value === _deal.id) {
          return estimatedRenewalEligibility
        }

        const offerTotals = OfferTotal.getOfferTotal(offer)
        const estimatedLoanPayments = getEstimatedLoanPayments(
          offerTotals.paybackAmount,
          fundedDate,
          offer?.paymentFrequency,
          offerTotals.paymentAmountByPeriod,
        )
        if (!estimatedLoanPayments?.length) return null

        const endDate = estimatedLoanPayments.at(estimatedLoanPayments.length - 1).entryDate
        if (estimatedRenewalEligibility
          && !/^(sba|erc|loc)/ig.test(_deal.loanProductCategoryType || '')
          && isDateAfter(estimatedRenewalEligibility, fundedDate)
          && (isDateAfter(endDate, estimatedRenewalEligibility) || isDateEqual(endDate, estimatedRenewalEligibility))) {
          return estimatedRenewalEligibility
        }

        const eligibilityPercent = renewalEligibilityPercent.value(offer)
        const eligiblePaidOffAmount = Math.round(offerTotals.paybackAmount * eligibilityPercent) / 100

        return estimatedLoanPayments.reduce((acc, payment) => {
          acc.sum += payment.amount
          if (acc.sum >= eligiblePaidOffAmount && !acc.date) {
            acc.date = payment.entryDate
          }
          return acc
        }, { sum: 0, date: null }).date
      }
    })

    const canReapply = computed(() => {
      //d = deal
      return (_deal, _acceptedOffer) => {
        const _renewEligibleDate = renewEligibleDate.value(_deal, _acceptedOffer)

        return Boolean(_renewEligibleDate) && isDateAfter(new Date(), _renewEligibleDate)
      }
    })

    const isDealPeriodEnded = computed(() => {
      return (_deal, _acceptedOffer) => {
        if (!_deal || !_acceptedOffer) {
          return false
        }
        const fundedDate = _deal?.dateClosed && isValidDate(new Date(compatibleDate(_deal.dateClosed)))
          ? new Date(compatibleDate(_deal.dateClosed))
          : null
        if (!fundedDate) {
          return false
        }
        const extendedOfferData = OfferTotal.getOfferTotal(_acceptedOffer)
        const estLoanPayments = getEstimatedLoanPayments(
          extendedOfferData.paybackAmount,
          fundedDate,
          _acceptedOffer?.paymentFrequency,
          extendedOfferData.paymentAmountByPeriod,
        )
        let endPeriodDate = null
        if (estLoanPayments?.length) {
          const lastPayment = estLoanPayments.slice(-1).at(0)
          if (lastPayment.entryDate) {
            endPeriodDate = lastPayment.entryDate
          }
        }
        if (_acceptedOffer.term && !endPeriodDate) {
          endPeriodDate = addMonths(fundedDate, _acceptedOffer.term);
        }
        if (!endPeriodDate) {
          return false
        }
        const today = startOfDay(Date.now())
        return isAfter(today, startOfDay(endPeriodDate))
      }
    })

    const prevFundedLoans = computed(() => {
      const fundedDeals = (sortedDeals.value || []).filter((deal) => {
        return ['optin', 'ppp'].includes(deal.autoSubmitType) === false &&
          deal.loanProductOfferType === 'tier-1' &&
          deal.stage === 'funded' &&
          deal.id !== activeDeal.value?.id;
      });
      if (!fundedDeals.length) {
        return [];
      }
      const fundedLoans = []
      for (const fundedDeal of fundedDeals) {
        const acceptedOffer = fundedDeal?.acceptedOffer
        if (!acceptedOffer) {
          continue;
        }
        const isCanReapply = canReapply.value(fundedDeal, acceptedOffer)
        const isEnded = isDealPeriodEnded.value(fundedDeal, acceptedOffer)
        const isPPP = PPP_LOAN_TYPES.includes(fundedDeal?.loanProductCategoryType)
        fundedLoans.push({ deal: fundedDeal, acceptedOffer, canReapply: isCanReapply, isPeriodEnded: isEnded, isPPPDeal: isPPP })
      }

      return fundedLoans
    })

    const normalizedEligibleOffers = computed(() => {
      return (eligibleOffers.value || []).map((offer) => {
        const { eligibleOfferType } = offer
        if (eligibleOfferType === 'tier-2') {
          const submissionState = getLenderSubmissionDealState(offer)
          return { ...offer, submissionState }
        } else if (['tier-3', 'alternative-product'].includes(eligibleOfferType)) {
          const optIn = (optInsStore.allOptions || []).find((opt) => opt.id === offer.id)
          const submissionState = optIn?.clickedAt ? 'Closing' : 'Option Available'
          return optIn ? { ...optIn, eligibleOfferType, submissionState, lenderName: optIn.name } : null
        } else if (eligibleOfferType === 'credit-card') {
          const creditCard = (creditCardsStore.creditCards || []).find((card) => card.id === offer.id)
          const submissionState = creditCard?.clickedAt ? 'Closing' : 'Option Available'
          return creditCard ? { ...creditCard, eligibleOfferType, submissionState, lenderName: creditCard.advertiserName } : null
        } else {
          return offer
        }
      }).filter((offer) => Boolean(offer))
    })

    const availableOffers = computed(() => {
      const activeDeals = (deals.value || []).filter((deal) => {
        return (!deal.autoSubmitType || ['qualified', 'vamp', 'decision_for_approval', 'decision_for_preapproval'].includes(deal.autoSubmitType)) &&
          deal.loanProductOfferType === 'tier-1' &&
          ['underwriting', 'closing'].includes(deal.stage) &&
          ['declined', 'offerDeclined'].includes(deal.status) === false
      });

      const publishedOffers = activeDeals.flatMap((deal) => {
        return (deal.offers || [])
          .filter((offer) => Boolean(offer.publishedToBorrower))
          .map((offer) => ({
            ...offer,
            loanProductCategoryType: offer.loanProductType,
            loanProductLenderId: deal.lenderId,
            originationFeePercent: offer.originationFee,
            factorRate: offer.factor,
            isStaticOffer: true,
          }))
      });

      const approvalsFromILLenders = approvalsStore.approvals.filter((approval) => {
        return IL_CLIENT_LENDERS_IDS.includes(approval.loanProductLenderId)
      })
      const buildOutput = (option) => option
      const initialChange = (option) => option

      return [
        ...publishedOffers,
        ...offerOptionsStore.availableOptions,
        ...approvalsFromILLenders
      ].filter((option) => {
        // magic number loanProductLenderId to allow Cornerbank approvals to show up in marketplace automatically for testing purposes
        return option.amount > 0
          || IL_CLIENT_LENDERS_IDS.includes(option.loanProductLenderId)
          || (option.loanProductLenderId === 47793 && borrowerStore.borrower.isTest);
      }).sort(sortOptions.bind(this, approvalsStore.buildOutput, approvalsStore.initialChange, IL_CLIENT_LENDERS_IDS))
    })

    const availableOptions = computed(() => {
      const isSelfServe = experimentsStore.activeUserExperimentByName('BP Loan Options Self Serve')
      const isStandardExperience = !isSelfServe
        && !rootStore.isEmbedded
        && !affiliateCustomizationStore.isIntelligentLendingAffiliate
      if (isStandardExperience) {
        return availableOffers.value
      }

      return [
        ...approvalsStore.approvals,
        ...offersStore.publishedOffers,
        ...offerOptionsStore.availableOptions,
      ].filter((approvalOrOffer) => {
        return approvalOrOffer.amount ? approvalOrOffer.amount > 0 : true
      }).sort(sortOptions.bind(this, approvalsStore.buildOutput, approvalsStore.initialChange, IL_CLIENT_LENDERS_IDS))
    })

    const featuredAlternativeOption = computed(() => {
      return (normalizedEligibleOffers.value || [])
        .find((offer) => offer.isFeatured && Boolean(offer.featureData))
    })

    const isActiveDealSbaDeal = computed(() => sbaLoanCategoryTypes.includes(activeDeal.value?.loanProductCategoryType ?? ''))

    const isActiveILClientDeal = computed(() => IL_CLIENT_LENDERS_IDS.includes(activeDeal.value?.lenderId ?? null))

    const hasAvailableSbaOption = computed(() => {
      const isILBorrower = affiliateCustomizationStore.isIntelligentLendingAffiliate
      const isStandardBorrower = !rootStore.isEmbedded && !isILBorrower

      return (availableOptions.value || []).some((option) => {
        return sbaLoanCategoryTypes.includes(option.loanProductCategoryType || option.loanProductType) &&
          (isILBorrower || (isStandardBorrower && IL_CLIENT_LENDERS_IDS.includes(option.loanProductLenderId || option.lenderId)))
      })
    })

    const isSbaOption = computed(() => {
      const isILBorrower = affiliateCustomizationStore.isIntelligentLendingAffiliate
      const isStandardBorrower = !rootStore.isEmbedded && !isILBorrower

      return (option) => {
        return sbaLoanCategoryTypes.includes(option.loanProductCategoryType || option.loanProductType) &&
          (isILBorrower || (isStandardBorrower && IL_CLIENT_LENDERS_IDS.includes(option.loanProductLenderId || option.lenderId)))
      }
    })

  /*
     █████   ██████ ████████ ██  ██████  ███    ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
    ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
    ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
    ACTIONS
    ! - - Actions calling other actions in the same store must use `this.actionName(...)`
    ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
    ! - - Computeds and refs will work fine, and should be called directly though.
  */

  function calculateProgress(_deal) {
    // d = deal
    const isInactive = _deal.stage === 'inactive'
      if (isInactive) {
        return {
          step: 'inactive',
          status: _deal.status
        }
      }

      const isFunded = !!_deal.dateClosed
      if (isFunded) {
        const fundedTime = _deal.dateClosed

        return {
          step: 'funded',
          status: 'complete',
          since: fundedTime
        }
      }

      const documents = _deal.documents
      if (documents && documents.length > 0) {
        const hasContract = some(documents, (document) =>
          includes(document.category, 'contract')
        )

        if (hasContract) {
          const hasCompleteContract = some(
            documents,
            (document) => document.category === 'contractSigned'
          )
          const furthestType = hasCompleteContract
            ? 'contractSigned'
            : 'contractUnsigned'
          const furthestContracts = documents.filter(
            (document) => document.category === furthestType
          )
          const furthestContractTimestamp = maxBy(
            furthestContracts,
            (contract) => {
              return getTime(parseISO(contract.modified))
            }
          )

          return {
            step: 'contract',
            status: hasCompleteContract ? 'complete' : 'incomplete',
            since: furthestContractTimestamp.modified
          }
        }
      }

      const offers = _deal.offers
      if (offers && offers.length > 0) {
        const hasOffer = some(offers, (offer) => !offer.borrowerDeclined)

        if (hasOffer) {
          const hasCompleteOffer = some(
            offers,
            (offer) => offer.accepted || offer.borrowerAccepted
          )
          const latestOffer = maxBy(offers, (offer) => {
            return getTime(parseISO(offer.modified))
          })

          return {
            step: 'offer',
            status: hasCompleteOffer ? 'complete' : 'incomplete',
            since: latestOffer?.modified
          }
        }
      }

      return {
        step: 'application',
        status: 'awaitingOffers'
      }
  }


  /**
     * Get deals from api.
     *
     * Read this is as "get deals and their offers" instead of "get only deals
     * that have offers".
     *
     * If checkPartnerDeals is true, we save it to state. Currently only
     * supporting BlueVine (as of 4/6/22).
     *
     * @param {boolean} checkPartnerDeals - Check for partner deals.
     * @returns void
     */
  async function getDealsWithOffers(checkPartnerDeals = false) {
      // If we ain't got no borrower, don't proceed.
      const borrowerId = borrowerStore.borrowerId
      if (!borrowerId) return

      const { data, status } = await $axios.get(
        `${env('apiUrl')}/borrower/${borrowerId}/deals`
      )

      // Do nothing if status isn't 200 or there are no deals. State
      // above initializes deals to [].
      const _deals = data && data.data ? data.data : []
      if (status !== 200 || !_deals.length) {
        return
      }

      // See if this is a partner deal.
      if (checkPartnerDeals) {
        const loanProductIds = _deals.map((d) => {
          return d.loanProductId
        })
        hasBlueVineDeal.value = intersection([837], loanProductIds).length !== 0 // BlueVine dealId = 837
      }

      const inLoanOptionExperiment = experimentsStore.activeUserExperimentByName('BP Loan Options Self Serve')
      // Remove un-needed information from deals.
      const cleanDeals = _deals.map((d) => {
        return {
          id: d.id,
          stage: d.stage,
          status: d.status,
          type: d.type,
          autoSubmitType: d.autoSubmitType,
          sbaLoanNumber: d.sbaLoanNumber || null,
          lenderId: get(d, 'loan_product_lender.id'),
          lenderName: get(d, 'loan_product_lender.name'),
          lenderPublicPhone: get(d, 'loan_product_lender.publicPhone'),
          loanProductId: d.loanProductId,
          automaticContractRequest: get(d, 'loan_product.automaticContractRequest'),
          loanProductCategoryType: get(d, 'loan_product.category.type'),
          lenderDomain: get(d, 'loan_product_lender.domain'),
          offers: d.offers || [],
          documents: d.many_documents || d.documents || [],
          loanType: get(d, 'loan_product.category.name') || 'Loan Product',
          categoryShortName:
            get(d, 'loan_product.category.shortName') || 'loanProduct',
          loanProductOfferType: d.loanProductOfferType,
          maxOffer: parseInt(
            get(maxBy(d.offers, 'amount'), 'amount') || '0.00'
          ),
          acceptedOffer: d.acceptedOfferId
            ? d.offers.filter(
                (offer) => offer.id === d.acceptedOfferId
              )[0]
            : undefined,
          dateClosed: d.dateClosed,
          created: d.created,
          applications: d.applications,
          lastActivity: d.last_deal_activity,
          deleted: d.deleted,
          modified: d.modified,
          contract: d.contract
        }
      }).filter((d) => {
        // Filter out any deals with a maxOffer that is not greater than 0 for those we are presenting offers to directly
        if (inLoanOptionExperiment) {
          return d.maxOffer > 0 || !d.offers.length
        }
        return true
      })

      if (cleanDeals.length) {
        // Calculate Progress on each cleanDeal
        for (let i = 0; i < cleanDeals.length; i++) {
          cleanDeals[i].progress = await this.calculateProgress(cleanDeals[i])
        }

        deals.value = cleanDeals
        visibleDeals.value = cleanDeals.filter((_deal) => {
          if (_deal.stage === 'inactive') {
            return false
          }

          if (_deal.type === 'draw') {
            return false
          }

          if (_deal.acceptedOffer) {
            return true
          }

          let validOffers = _deal.offers.filter((offer) => {
            return !offer.borrowerDeclined && !offer.deleted && !offer.declined
          })

          return validOffers.length > 0
        })

        visibleDeals.value = visibleDeals.value.map((_deal) => {
          let acceptedOffers = _deal.offers.filter((offer) => {
            return offer.accepted
          })

          let validOffers = acceptedOffers.length
            ? acceptedOffers
            : _deal.offers.filter((offer) => {
                return (
                  !offer.borrowerDeclined && !offer.deleted && !offer.declined
                )
              })

          _deal.offers = validOffers
          return _deal
        })
        loaded.value = true
      }
  }

  async function safeGetDealsWithOffers() {
    if (!deals.value?.length){
      await this.getDealsWithOffers()
    }
    return deals.value
  }

  async function acceptOffer({ offerId }) {
    if (!offerId) return

      const now = new Date().getTime()
      const postData = JSON.stringify({ accepted: now, borrowerAccepted: now })
      const { data, status } = await $axios.put(
        `${env('apiUrl')}/offer/${offerId}`,
        postData,
        { headers: { 'Content-Type': 'application/json' } }
      )

      const offer = data.data

      if (status !== 200 && !offer.accepted && !offer.borrowerAccepted) {
        log.error('Failed to accept offer', { status, offerId })
      }

      // TODO: Remove this once pusher is added
      await this.getDealsWithOffers()
  }

    // TODO: This function doesn't seem to have much to do with Deals state.
    // It should probably be moved elsewhere.
    // Generate a SignNow contract as a Document Group.
    async function sendLenderContract(dealId) {
      const url = `${env('apiUrl')}/embedded-app/sign-now-contract`;
      const body = {
        'dealId': dealId,
      }
      const urlResponse = await $axios.post(url, JSON.stringify(body), {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        return res.data.data
      }).catch(() => {
        return null
        // TODO: How do we report an error here? Because we definitely need to.
      })
    }

    async function changeDealStatusToContractIn(dealId) {
      const dealIndex = deals.value.findIndex(_deal=> _deal.id === dealId)
      const dealsCopy = deals.value
      dealsCopy[dealIndex].status = 'contractIn'
      deals.value = dealsCopy
    }

  async function declineOffer({ declineReasonId, offerId, declinedOtherReason }) {
    if (!offerId || !declineReasonId) return

      const now = new Date().getTime()
      const postData = JSON.stringify({
        borrowerDeclined: now,
        deadDispositionId: declineReasonId,
        declinedOtherReason: declinedOtherReason
      })
      const { data, status } = await $axios.put(
        `${env('apiUrl')}/offer/${offerId}`,
        postData,
        { headers: { 'Content-Type': 'application/json' } }
      )

      const offer = data.data

      if (status !== 200 && !offer.declined && !offer.borrowerDeclined) {
        log.error('Failed to decline offer', {
          status,
          offerId,
          declineReasonId
        })
      }

      // TODO: Remove this once pusher is added
      await this.getDealsWithOffers()
  }

  async function getEligibleOffers () {
    const borrowerId = borrowerStore.borrowerId
    if (!borrowerId) return

    let response;

    try {
      response = await $axios.get(`${env('apiUrl')}/borrower/${borrowerId}/eligible-offers`)
      if (response.statusCode > 299) {
        throw new Error(`Failed with code: ${response.statusCode}`, {
          cause: response.errors
        });
      } else if (!response.data?.data) {
        throw new Error('Response data from API should not be empty')
      }
    } catch (e) {
      log.error(e.message, {
        err: e,
        errMessage: e.message,
        borrowerId,
      });
      return response;
    }
    const offers = response?.data?.data;
    eligibleOffersLoaded.value = true;
    if (!offers.length) {
      eligibleOffers.value = [];
      return response;
    }

    let _eligibleOffers
    // if loanProductOfferType exists on the first object then it is a tier-2 we will only return that one
    if (offers.at(0)?.eligibleOfferType === 'tier-2') {
      _eligibleOffers = offers.slice(0, 1);
    } else if (offers.length >= 3) { // else if return the first 3 (if there is at least 3)
      _eligibleOffers = offers.slice(0, 3);
    } else { // else return whatever else there is
      _eligibleOffers = offers;
    }

    await optInsStore.addOptions(
      _eligibleOffers.filter((offer) => ['tier-3', 'alternative-product'].includes(offer.eligibleOfferType))
    )
    creditCardsStore.addCreditCards(
      _eligibleOffers.filter((offer) => offer.eligibleOfferType === 'credit-card')
    )
    eligibleOffers.value = _eligibleOffers

    return response;
  }

  async function getPossibleEligibleOffers() {
    const borrowerId = borrowerStore.borrowerId
    if (!borrowerId) return

    let response;
    try {
      response = await $axios.get(`${env('apiUrl')}/borrower/${borrowerId}/eligible-offers`, { params: { includePotential: 'true' } })
      if (response.statusCode > 299) {
        throw new Error(`Failed with code: ${response.statusCode}`, {
          cause: response.errors
        });
      } else if (!response.data?.data) {
        throw new Error('Response data from API should not be empty')
      }
    } catch (e) {
      log.error(e.message, {
        err: e,
        errMessage: e.message,
        borrowerId,
      });
      return response;
    }

    const offers = response?.data?.data;
    if (!offers.length) {
      return response;
    }


    let _possibleOffers
    // if loanProductOfferType exists on the first object then it is a tier-2 we will only return that one
    if (offers.at(0)?.eligibleOfferType === 'tier-2') {
      _possibleOffers = offers.slice(0, 1);
    } else if (offers.length >= 3) { // else if return the first 3 (if there is at least 3)
      _possibleOffers = offers.slice(0, 3);
    } else { // else return whatever else there is
      _possibleOffers = offers;
    }

    const cardAdvertisers = []
    possibleEligibleOffers.value = _possibleOffers.map((offer) => {
      const { eligibleOfferType } = offer
      if (eligibleOfferType === 'tier-2') {
        return { ...offer, submissionState: getLenderSubmissionDealState(offer) }
      }
      if (['tier-3', 'alternative-product'].includes(eligibleOfferType)) {
        return { id: offer.id, eligibleOfferType, submissionState: 'Submitted', lenderName: offer.name, order: offer.order }
      }
      if (eligibleOfferType === 'credit-card') {
        // store only one credit card per advertiser
        if (cardAdvertisers.includes(offer.advertiserName)) {
          return null
        }
        cardAdvertisers.push(offer.advertiserName)
        return { id: offer.id, eligibleOfferType, submissionState: 'Submitted', lenderName: offer.advertiserName, order: offer.order }
      }
      return offer
    }).filter((offer) => Boolean(offer))

    return response
  }

  return {
    //state
    deals,
    eligibleOffers,
    hasBlueVineDeal,
    visibleDeals,
    loaded,
    eligibleOffersLoaded,
    possibleEligibleOffers,
    // getters
    approval,
    hasActiveDeals,
    hasFundedDeals,
    hasDealInClosingStatus,
    awaitingResponseDeals,
    hasFundedTierOneDeals,
    hasActiveTierOneDeals,
    sortedDeals,
    output,
    dealsCountByCategoryType,
    dealWithAcceptedOffer,
    activeDeal,
    renewalEligibilityPercent,
    renewEligibleDate,
    canReapply,
    latestBorrowerRenewalEstDealId,
    normalizedEligibleOffers,
    availableOffers,
    isDealPeriodEnded,
    prevFundedLoans,
    availableOptions,
    featuredAlternativeOption,
    isActiveDealSbaDeal,
    isActiveILClientDeal,
    hasAvailableSbaOption,
    isSbaOption,

    //actions
    calculateProgress,
    getDealsWithOffers,
    safeGetDealsWithOffers,
    getEligibleOffers,
    getPossibleEligibleOffers,
    acceptOffer,
    changeDealStatusToContractIn,
    declineOffer,
    sendLenderContract,
  }
})
