import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { localStorageService } from '~/libs/local-storage.service'
import { MBOX_DETAILS } from '~/libs/adobe-target'
import env from '~/libs/env'
import isPOBox from '~/libs/po-box-check'
import { compatibleDate } from '~/libs/date-helpers'

import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useBorrowerStore } from '~/store/borrower'
import { useDocumentsStore } from '~/store/documents'
import { useExperimentsStore } from '~/store/experiments'
import { useOwnershipStore } from '~/store/ownership'
import { usePortalStore } from '~/store/portal'
import { useRootStore } from '~/store/root'

import * as ApprovalCalculatorUtility from '@lendiodevs/lendio-js-utilities/lib/approval/ApprovalCalculator.utility.js'
import * as generalFormulas from '@lendiodevs/lendio-js-utilities/dist/formulas/general-formulas'
import { configureCalculator } from '@lendiodevs/lendio-js-utilities/dist/approval-calculator'

import accounting from 'accounting'

import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import { differenceInDays, intervalToDuration, format, addDays, isBefore } from 'date-fns'
import { normalizeLoanType } from '~/libs/loan-product-info'

function sortApprovals(_approvals, _sortBy) {
  _approvals = orderBy(
    _approvals,
    [`options[0].${_sortBy.attribute}`],
    [_sortBy.order]
  )
  return _approvals
}

export const useApprovalsStore = defineStore('approvals', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  const appAnalyticsStore = useAppAnalyticsStore()
  const borrowerStore = useBorrowerStore()
  const documentsStore = useDocumentsStore()
  const experimentsStore = useExperimentsStore()
  const ownershipStore = useOwnershipStore()
  const portalStore = usePortalStore()
  const rootStore = useRootStore()

  /*
    ███████ ████████  █████  ████████ ███████
    ██         ██    ██   ██    ██    ██
    ███████    ██    ███████    ██    █████
         ██    ██    ██   ██    ██    ██
    ███████    ██    ██   ██    ██    ███████
    STATE
  */
  const unfilteredApprovals = ref([])
  const approvals = ref([])
  const sortBy = ref(null)
  const currentApproval = ref(null)
  const approvalsLoaded = ref(false)
  const changes = ref([]) //changes
  const contractRequests = ref([]) // contractRequests
  const contractRequestsThroughTasks = ref([]) // contractRequestsThroughTasks
  const points = ref(null)
  // const modalName = ref('')
  const contractRequestsLoaded = ref(false)
  const showAdjustmentCalculator = ref(true)
  const showChangeIndicators = ref(true)
  const isSupportedRenewal = ref(false)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const getCurrentApproval = computed(() => {
    if (!currentApproval.value) {
      if (process.client) {
        const ls = localStorageService.getItem('currentApproval')
        return ls ? JSON.parse(ls) : null
      }
    }
    return currentApproval.value
  })

  const changesByApprovalId = computed(() => {
    const _changes = changes.value
    return (approvalId) => {
      return _changes.filter((change) => {
        return change.approvalId === approvalId
      })
    }
  })

  const initialChange = computed(() => {
    return (approval) => {
      if (!approval) return null
      const _changes = changesByApprovalId.value(approval.id)
      if (_changes.length) {
        return _changes[0]
      } else {
        return null
      }
    }
  })

  const currentChange = computed(() => {
    return (approval) => {
      if (!approval) return null
      const _changes = changesByApprovalId.value(approval.id)
      if (_changes.length) {
        return _changes[_changes.length - 1]
      } else {
        return null
      }
    }
  })

  const hasModifiedApproval = computed(() => {
    const _changes = changes.value
    return (approval) => {
      const approvalChanges = _changes.filter((change) => {
        return change.approvalId === approval.id
      })
      return approvalChanges.length > 1
    }
  })

  const approvalById = computed(() => {
    const _approvals = approvals.value
    return (approvalId) => {
      return _approvals.find(approval => approval.id === approvalId)
    }
  })

  const approvalOptionByTermAndFrequency = computed(() => {
    const _approvals = approvals.value
    return (term, paymentFrequency) => {
      return _approvals.find(a => a.id === currentApproval.value.id)
        .options.find(o => o.term === term
          && o.paymentFrequency === paymentFrequency
        )
    }
  })

  const checkoutLinkExpected = computed(() => {
    return (_dealId) => {
      const _contract = contractRequests.value.find((_contractRequest) => {
        return _contractRequest.dealId === _dealId
      })
      return _contract ? _contract.checkoutLinkExpected : false
    }
  })

  const contractRequestsGetter = computed(() => {
    if (!contractRequests.value.length) {
      if (process.client) {
        let ls = localStorageService.getItem('contractRequests')
        return ls ? JSON.parse(ls) : []
      }
    }
    return contractRequests.value
  })

  const contractRequestsThroughTasksGetter = computed(() => {
    if (!contractRequestsThroughTasks.value.length) {
      if (process.client) {
        const ls = localStorageService.getItem('contractRequestsThroughTasks')
        return ls ? JSON.parse(ls) : []
      }
    }
    return contractRequestsThroughTasks.value
  })

  const contractRequestSentForDeal = computed(() => {
    const _contractRequests = contractRequests.value
    return (dealId) => {
      return _contractRequests.find(contractRequest => {
        return contractRequest.dealId === dealId && contractRequest.sent !== null;
      });
    }
  })

  const termFrequencyOptions = computed(() => {
    return (options, term) => {
      return ApprovalCalculatorUtility.getAvailablePaymentFrequencies(options, term)
    }
  })

  const hasContractRequests = computed(() => {
    return contractRequestsGetter.value.length;
  })

  const hasContractRequestsThroughTasks = computed(() => {
    return contractRequestsThroughTasksGetter.value.length;
  })

  const paramBoundariesByType = computed(() => {
    const _approvals = approvals.value

    return (options, type) => {
      if (!_approvals || !options) return {}
      return ApprovalCalculatorUtility.getParamBoundaries(options, type)
    }
  })

  const getFactorRate = computed(() => {
    return (approval, option) => {
      const _points = setAchPoints(approval, option)

      if (!option) return 0
      const { buyRate, limits } = option
      const { originationPercent } = limits
      if (!buyRate || !originationPercent) return 0
      const factorRate = ApprovalCalculatorUtility.getFactorRate({
        pointsTradable: approval.pointsTradable,
        points: _points,
        buyRate: buyRate,
        maxOriginationPercent: originationPercent.max,
        originationPercent: originationPercent.max
      });
      return factorRate
    }
  })

  const achApprovalOptionCalculatedValues = computed(() => {
    return (approval, optionId, changeAmount) => {
      const option = findApprovalOptionById(approval.id, optionId)
      const factorRate = ApprovalCalculatorUtility.toFixedNumber(getFactorRate.value(approval, option), 3, 10)
      const calculationType = option.limits.paymentAmountMax ? 'maxPayment' : 'standard'
      const maxAmount = ApprovalCalculatorUtility.getOptionAvailableAmount({
        option: option,
        calculationType: calculationType,
        factor: factorRate
      })
      const payback = ApprovalCalculatorUtility.toFixedNumber(factorRate * (changeAmount ? changeAmount : maxAmount), 4)
      const paymentAmount = ApprovalCalculatorUtility.toFixedNumber((payback * 10000) / (option.payments * 10000), 2, 10)
      return {
        factorRate,
        payback,
        paymentAmount,
        maxAmount
      }
    }
  })

  const staticOfferContractRequestBody = computed(() => {
    return (approval) => {
      let interestRate;
      if (approval.periodicRate && approval.periodsPerYear) {
        const periodicRate = generalFormulas.toFixedNumber(approval.periodicRate, 10)
        interestRate = periodicRate * approval.periodsPerYear;
      }

      return {
        dealId: approval.dealId,
        terms: {
          amount: approval.amount,
          annualInterestRate: approval.annualRate ? approval.annualRate : approval.annualInterestRate,
          apr: approval.apr,
          commission: approval.commission,
          creditLimit: approval.creditLimit ? approval.creditLimit : approval.amount,
          drawFeePercent: approval.drawFeePercent,
          drawFeeAmount: approval.drawFeeAmount,
          factorRate: approval.factorRate ? approval.factorRate : approval.factor,
          initialDrawAmount: approval.amount,
          interestRate,
          netFundedAmount: approval.netFundedAmount,
          originationFeeAmount: approval.originationFeeAmount,
          originationFeePercent: approval.originationFeePercent ? approval.originationFeePercent : approval.originationFee,
          payback: approval.payback,
          paymentAmount: approval.paymentAmount,
          paymentCount: approval.payments ? approval.payments : approval.numPayments,
          paymentFrequency: approval.paymentFrequency,
          periodicRate: approval.periodicRate,
          periodsPerYear: approval.periodsPerYear,
          variablePercentOfRevenue: approval.variablePercentOfRevenue,
          points: approval.points,
          term: approval.term,
          termType: 'months',
        },
        lenderOfferId: null,
        termsType: approval.loanProductCategoryType,
        offerOptionId: approval.isOfferOption ? approval.id : null,
      }
    }
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function requestContract(approval) {
    const url = `${env('apiUrl')}/deal/contract-request/offer`;
    const contractRequest = createContractRequestBody(approval)
    if (!approval.isMockData) {
      await $axios.post(url, JSON.stringify(contractRequest), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } else {
      this.setContractRequests([contractRequest])
    }
  }

  async function sendContractRequest(contractRequest) {
    const url = `${env('apiUrl')}/deal/contract-request/${contractRequest.id}/send`
    await $axios.post(url, {}, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    await this.getContractRequestsByDealId(contractRequest.dealId)
  }

  async function submitContractRequest(approval) {
    await this.requestContract(approval)
    await this.getContractRequestsByDealId(approval.dealId)
  }

  function addChange(change) {
    const _changes = changesByApprovalId.value(change.approvalId)
    if (_changes.length > 0) {
      const lastChange = _changes[_changes.length - 1]
      if (isEqual(lastChange, change)) {
        return
      }
    }

    changes.value = [...changes.value, change] //push
  }

  async function setDefaultChanges() {
    if (!approvals.value.length) return
    approvals.value.forEach((approval) => {
      if (approval.options.length) {
        const change = {
          approvalId: approval.id,
          optionId: approval.options[0].id,
          amount: approval.options[0].maxAmount,
          paymentFrequency: approval.options[0].paymentFrequency,
          term: approval.options[0].term
        }
        this.addChange(change)
      }
    })
  }

  function sortApprovalsByDefault() {
    let _approvals = this.sortApprovals(approvals.value, { attribute: 'maxAmount', order: 'desc' })
    if (isSupportedRenewal.value) {
      _approvals = _approvals.slice(0, 1)
    }
    this.setApprovals(_approvals)
  }

  function sortApprovalOptionsByDefaults() {
    let _approvals = cloneDeep(approvals.value)
    _approvals.forEach(approval => {
      approval.options = orderBy(
        approval.options,
        ['maxAmount', 'term'], ['desc', 'desc']
      )
    })
    this.setApprovals(_approvals)
  }

  function setApprovals(_approvals) {
    _approvals.forEach(approval => {
      const localStorageApprovals = JSON.parse(localStorageService.getItem('approvals') || '[]')
      const localStorageExisting = localStorageApprovals.find(a => a.id === approval.id)
      const stateExisting = approvals.value.find(a => a.id === approval.id)
      if (localStorageExisting && stateExisting) {
        approval.isNew = stateExisting.isNew
        approval.isUpdated = stateExisting.isUpdated
      } else {
        approval.isUpdated = localStorageApprovals.some(a => a.dealId === approval.dealId && a.id !== approval.id)
        approval.isNew = !localStorageApprovals.some(a => a.dealId === approval.dealId) && !approval.isUpdated
        if (approval.isNew) {
          localStorageService.setItem('approvals', JSON.stringify([
            ...localStorageApprovals,
            approval
          ]))
        }
        if (approval.isUpdated) {
          localStorageService.setItem('approvals', JSON.stringify([
            ...localStorageApprovals.filter(a => a.dealId !== approval.dealId),
            approval
          ]))
        }
      }
    })
    approvals.value = _approvals
  }

  function isApprovalExpired(approval) {
    let approvalCreated = approval.created ? new Date(compatibleDate(approval.created)) : null

    if (approvalCreated) {
      const expiredTime = addDays(approvalCreated, 30)

      if (isBefore(expiredTime, new Date())) {
        return true
      }
    }
    return false
  }

  function openModal() {
    console.log('Placeholder open modal function')
  }

  function setAchPoints(approval, option) {
    if (option.limits.points.max === 0) {
      points.value = approval.points
    } else {
      points.value = approval.points > option.limits.points.max ? option.limits.points.max : approval.points
    }

    return points.value
  }

  function setDefaultApprovalOptions() {
    const defaultApprovals = approvals.value.map(approval => {
      approval.options.forEach(option => {
        switch (approval.loanProductCategoryType) {
          case 'ach':
            const { factorRate, payback, paymentAmount, maxAmount } = achApprovalOptionCalculatedValues.value(approval, option.id)
            option.factorRate = factorRate
            option.payback = payback
            option.paymentAmount = paymentAmount
            option.maxAmount = maxAmount
            break;
          default:
            break;
        }
      })
      return approval
    })
    this.setApprovals(defaultApprovals)
  }

  async function requestApprovals(force = false, resetChanges = false) {
    const borrowerId = borrowerStore.borrowerId
    if (!borrowerId) return


    if (approvals.value?.length && !force) {
      return
    }

    const response = await $axios.get(`${env('apiUrl')}/approval/borrower/${borrowerId}`)
    let _approvals = get(response, 'data.data')
    // This will be null if the user's session has timed out. The 401 will be handled separately, we can just return here
    if (!_approvals) return;

    if (_approvals.length) {
      const renewalApprovals = _approvals.filter((approval) => {
        return approval.isSupportedRenewal
      })

      if (renewalApprovals.length) {
        _approvals = [renewalApprovals[0]]
        const lastAcceptedOfferPoints = _approvals[0].lastAcceptedOfferPoints
        if (lastAcceptedOfferPoints) {
          points.value = lastAcceptedOfferPoints
        }
        isSupportedRenewal.value = true
      }

      // reset changes
      if (resetChanges) {
        changes.value = []
      }

      this.setApprovals(_approvals.filter(approval => !isApprovalExpired(approval)))
      unfilteredApprovals.value = _approvals

      this.setDefaultApprovalOptions()
      this.sortApprovalsByDefault()
      this.sortApprovalOptionsByDefaults()
      this.setDefaultChanges()
    } else {
      // no approvals available
      this.clearApprovals()
    }

    approvalsLoaded.value = true
  }

  function sortApprovalsBy(_sortBy) {
    let sortedApprovals = cloneDeep(approvals.value)
    sortedApprovals = this.sortApprovals(sortedApprovals, { attribute: _sortBy, order: 'asc' })
    sortBy.value = _sortBy
    this.setApprovals(sortedApprovals)
  }

  function addChangeToApprovals(change) {
    let _approvals = cloneDeep(approvals.value)
    const approvalIndex = _approvals.findIndex(a => a.id === change.approvalId)
    let optionIndex = _approvals[approvalIndex].options.findIndex(o => o.id === change.optionId)

    switch (_approvals[approvalIndex].loanProductCategoryType) {
      case 'ach':
        const { factorRate, payback, paymentAmount } = achApprovalOptionCalculatedValues.value(_approvals[approvalIndex], change.optionId, change.amount)
        _approvals[approvalIndex].options[optionIndex].factorRate = factorRate
        _approvals[approvalIndex].options[optionIndex].payback = payback
        _approvals[approvalIndex].options[optionIndex].paymentAmount = paymentAmount
        break;
      default:
        break;
    }

    _approvals[approvalIndex].options.unshift(_approvals[approvalIndex].options.splice(optionIndex, 1)[0])
    let sortedApprovals = this.sortApprovals(_approvals, { attribute: sortBy.value, order: 'asc' })
    this.setApprovals(sortedApprovals)
  }

  function setContractRequests(_contractRequests) {
    contractRequests.value = _contractRequests
  }

  function setContractRequestsThroughTasks(_contractRequestsThroughTasks) {
    const filtered = _contractRequestsThroughTasks.filter(task => {
      return task.type === 'contracts'
    })
    contractRequestsThroughTasks.value = filtered
    if (process.client) {
      localStorageService.setItem('contractRequestsThroughTasks',
        JSON.stringify(filtered)
      )
    }
  }

  async function getContractRequestsByDealId(dealId) {
    const url = `${env('apiUrl')}/deal/${dealId}/contract-request`;
    const response = await $axios.get(url)
    const _contractRequests = get(response, 'data.data')
    this.setContractRequests(_contractRequests)
    contractRequestsLoaded.value = true
  }

  async function getContractRequestsByBorrowerId(borrowerId) {
    const url = `${env('apiUrl')}/borrower/${borrowerId}/contract-request`
    const response = await $axios.get(url)
    const _contractRequests = get(response, 'data.data')
    this.setContractRequests(_contractRequests)
    contractRequestsLoaded.value = true
    return _contractRequests
  }

  async function getContractRequestsThroughTasks(borrowerId) {
    const url = `${env('apiUrl')}/borrower/${borrowerId}/tasks`;
    const response = await $axios.get(url);
    const _contractRequestsThroughTasks = get(response, 'data.data');
    this.setContractRequestsThroughTasks(_contractRequestsThroughTasks);
    contractRequestsLoaded.value = true
  }

  async function adobeTargetAdjustmentCalculatorTest() {
    const expIds = borrowerStore.borrowerExperiments
    let inApprovalsExperiment = expIds.includes(30)
    let inApprovalsBorrowerValidation = expIds.includes(33)

    if (!inApprovalsExperiment || inApprovalsBorrowerValidation) {
      return
    }

    const params = {}
    const mbox = MBOX_DETAILS.APPROVALS_CALCULATOR.NAME
    const searchTerm = 'showAdjustmentCalculator'
    const targetRes = await appAnalyticsStore.checkSingleTargetOffer({ mbox, searchTerm, params })
    if (targetRes) {
      showAdjustmentCalculator.value = true
    }
  }

  async function submitApprovalsTestBorrower(experimentIds = null) {
    const expIds = experimentIds || borrowerStore.borrowerExperiments
    let inApprovalsExperiment = expIds.includes(30)
    let inAutoSubmissionExperiment = expIds.includes(14)
    if (inApprovalsExperiment || inAutoSubmissionExperiment) return
    const borrower = await borrowerStore.getBorrower()
    const bvs = await borrowerStore.getBorrowerValues()
    const SYSTEM_USER_ID = ownershipStore.systemUserId
    const SELF_SERVE_USER_ID = ownershipStore.selfServeUserId
    const SELF_SERVE_RENEWAL_USER_ID = ownershipStore.selfServeRenewalUserId
    const hasPartner = get(rootStore, 'authUser.marketingData.affiliateId', false) &&
      get(rootStore, 'authUser.marketingData.hasOwnership', false)
    const poBoxCheck = (get(borrower, 'street') ? isPOBox(get(borrower, 'street')) : false) ||
      (get(borrower, 'street2') ? isPOBox(get(borrower, 'street2')) : false)
    const userId = get(borrower, 'userId')
    const currentlyAssigned = (userId !== SYSTEM_USER_ID || userId !== SELF_SERVE_USER_ID || userId !== SELF_SERVE_RENEWAL_USER_ID) || get(borrower, 'zeeId')
    const financeNumber = get(bvs, 'financeAmount.value') ? +get(bvs, 'financeAmount.value') : 0
    const params = {
      po_box: poBoxCheck,
      ein: !!get(bvs, 'federalstate_tax_id.value', null),
      entity_type: get(bvs, 'entity_type.value', null),
      credit_score: +get(bvs, 'creditScore.value'),
      monthly_revenue: +get(bvs, 'average_monthly_sales.value'),
      finance_amount: financeNumber,
      portal_qualified: portalStore.isQualifiedBorrower,
      is_assigned: !!currentlyAssigned,
      state: get(borrower, 'stateId'),
      has_statements: documentsStore.hasRequiredBankStatements,
      partner_owned: !!hasPartner,
    }
    const mbox = MBOX_DETAILS.APPROVALS_DISPLAY.NAME
    const searchTerm = 'approvalsQualified'

    const targetRes = await appAnalyticsStore.checkSingleTargetOffer({ mbox, searchTerm, params })
    if (targetRes) {
      const approvalExp = {
        id: 30,
        name: 'Approvals/Offers Display',
        group: 1
      }
      const approvalExpBorrowerValidation = {
        id: 33,
        name: 'Experiment Details: Approvals/Offers Borrower Validation',
        group: 1
      }

      await experimentsStore.postExperimentGroupsToApi([
        approvalExp,
        approvalExpBorrowerValidation
      ])
      await borrowerStore.setAssignedLenderUser(6839495)
    }
  }

  function resetChanges(approval) {
    const firstChangeIndex = changes.value.findIndex((change) => {
      return change.approvalId === approval.id
    })
    changes.value = changes.value.filter((change, index) => {
      return change.approvalId !== approval.id
        || (change.approvalId === approval.id && index === firstChangeIndex)
    })
  }

  function findApprovalOptionById (approvalId, optionId) {
    const _approvals = approvals.value
    if (approvalId && optionId && _approvals.length) {
      return _approvals.find((a) => a.id === approvalId)
        .options.find(o => o.id === optionId)
    } else {
      return null
    }
  }

  function calculateOriginationFee(amount, {
    originationPercent,
    originationAmount
  }) {
    const originationValues = {
      originationPercent: 0,
      originationAmount: 0,
    }
    const originationPercentMin = originationPercent?.min ? originationPercent.min : originationPercent;
    const originationAmountMin = originationAmount?.min ?? 0;
    const originationPercentProduct = originationPercentMin * amount / 100;

    if (originationPercentProduct > originationAmountMin) {
      originationValues.originationAmount = originationPercentProduct;
      originationValues.originationPercent = originationPercentMin;
    } else {
      originationValues.originationAmount = originationAmountMin;
      originationValues.originationPercent = originationAmountMin / amount * 100;
    }
    return originationValues
  }

  function buildAchOutput(approval, change) {
    const { factorRate, payback, paymentAmount } = achApprovalOptionCalculatedValues.value(approval, change.optionId, change.amount)
    const option = findApprovalOptionById(approval.id, change.optionId)
    const _points = setAchPoints(approval, option)
    const amount = parseInt(change.amount ? change.amount : option.limits.amount.max)

    const {originationAmount, originationPercent} = calculateOriginationFee(amount, option.limits);
    return {
      optionId: change.optionId,
      approvalId: change.approvalId,
      amount: change.amount,
      term: option.term,
      paymentFrequency: option.paymentFrequency,
      factorRate: factorRate,
      numOfPayments: option.payments,
      payments: option.payments,
      paymentAmount: paymentAmount,
      payback: payback,
      costOfCapital: (payback - change.amount),
      isAch: approval.loanProductName.includes('ACH'),
      loanType: approval.loanProductCategoryType,
      variablePercentOfRevenue: option.variablePercentOfRevenue,
      commission: change.amount * _points / 100,
      originationFeePercent: originationPercent,
      originationFeeAmount: originationAmount,
      formatted: {
        amount: accounting.formatMoney(change.amount, '$', 0),
        paymentAmount: option.variablePercentOfRevenue ? option.variablePercentOfRevenue : accounting.formatMoney(Math.ceil(paymentAmount), '$', 0),
        payback: accounting.formatMoney(payback, '$', 0),
        costOfCapital: accounting.formatMoney(payback - change.amount, '$', 0),
      }
    }
  }

function buildLocOutput(approval, change) {
    const option = findApprovalOptionById(approval.id, change.optionId)
    const periodicRate = option.periodicRate
    const amount = parseInt(change.amount ? change.amount : option.limits.drawAmount.max)
    const {
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule
    } = approval.calculatorConfig
    const annualRate = generalFormulas.getAnnualRate({
      periodicRate: periodicRate,
      periodsPerYear: option.periodsPerYear,
    })
    const dailyRate = generalFormulas.getDailyRate({
      annualRate: annualRate
    })
    const paymentAmount = generalFormulas.getPaymentAmount({
      drawAmount: amount,
      periodicRate: periodicRate,
      paymentCount: option.payments,
    })
    const paymentRate = generalFormulas.getPaymentRate({
      dailyRate: dailyRate,
      daysInPeriod: Number(option.daysInPeriod),
    })
    const calculateScheduleParams = {
      paymentCount: option.payments,
      paymentRate: paymentRate,
      paymentAmount: paymentAmount,
      startBalance: amount,
    }
    const calc = configureCalculator({
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule,
      calculateScheduleParams,
    })
    const {originationAmount, originationPercent} = calculateOriginationFee(amount, {
      originationPercent: option.drawFeePercent,
      originationAmount: option.limits.drawFeeAmount,
    });
    const finalPaymentAmount = generalFormulas.findFinalPaymentAmount(calc.paymentSchedule)
    const apr = calc.apr(
      amount,
      originationAmount,
      paymentAmount,
      option.payments,
      option.periodsPerYear,
      finalPaymentAmount
    )

  return {
      optionId: change.optionId,
      approvalId: change.approvalId,
      amount: amount,
      term: option.term,
      paymentFrequency: option.paymentFrequency,
      paymentAmount: paymentAmount,
      isAch: false,
      apr,
      annualRate,
      drawFee: originationAmount,
      drawFeePercent: originationPercent,
      loanType: approval.loanProductCategoryType,
      formatted: {
        amount: accounting.formatMoney(amount, '$', 0),
        paymentAmount: accounting.formatMoney(paymentAmount, '$', 0),
        isAch: approval.loanProductName.includes('ACH'),
        drawFeePercent: parseFloat(originationPercent).toFixed(2) + '%',
        periodicRate: {
          display: option.paymentFrequency.charAt(0).toUpperCase() + option.paymentFrequency.slice(1) + ' rate',
          value: parseFloat(periodicRate).toFixed(2) + '%'
        }
      }
    }
  }

function buildTermOutput (approval, change) {
    const option = findApprovalOptionById(approval.id, change.optionId)
    const periodicRate = option.periodicRate
    const amount = parseInt(change.amount ? change.amount : option.limits.amount.max)
    const paymentAmount = generalFormulas.getPaymentAmount({
      drawAmount: amount,
      periodicRate: periodicRate,
      paymentCount: option.payments,
    })
    const {
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule
    } = approval.calculatorConfig
    const annualRate = generalFormulas.getAnnualRate({
      periodicRate: periodicRate,
      periodsPerYear: option.periodsPerYear,
    })
    const dailyRate = generalFormulas.getDailyRate({
      annualRate: annualRate
    })
    const paymentRate = generalFormulas.getPaymentRate({
      dailyRate: dailyRate,
      daysInPeriod: Number(option.daysInPeriod),
    })
    const calculateScheduleParams = {
      paymentCount: option.payments,
      paymentRate: paymentRate,
      paymentAmount: paymentAmount,
      startBalance: amount,
    }
    const calc = configureCalculator({
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule,
      calculateScheduleParams,
    })
    const drawFee = calc.drawFeeAmount(amount, option.originationFeePercent)
    const finalPaymentAmount = generalFormulas.findFinalPaymentAmount(calc.paymentSchedule)

    const apr = calc.apr(
      amount,
      drawFee,
      paymentAmount,
      option.payments,
      option.periodsPerYear,
      finalPaymentAmount
    )
    const interest = generalFormulas.findTotalInterest(
      calc.paymentSchedule, option.term, option.payments, option.term)
    const payback = calc.payback(amount, interest)
    const netFundedAmount = calc.disbursement(amount, drawFee);
    const calculatedPeriodRate = generalFormulas.toFixedNumber((periodicRate || 0.0), 10);
    const {originationAmount, originationPercent} = calculateOriginationFee(amount, {
      originationPercent: option.originationFeePercent,
      originationAmount: option.limits.originationFeeAmount,
    });

    return {
      optionId: change.optionId,
      approvalId: change.approvalId,
      amount: amount,
      term: option.term,
      paymentFrequency: option.paymentFrequency,
      isAch: false,
      apr: apr,
      annualRate: annualRate,
      drawFee: drawFee,
      originationFeePercent: originationPercent,
      originationFeeAmount: originationAmount,
      numOfPayments: option.payments,
      payments: option.payments,
      paymentAmount: paymentAmount,
      periodsPerYear: option.periodsPerYear,
      periodicRate: calculatedPeriodRate,
      netFundedAmount,
      payback,
      loanType: approval.loanProductCategoryType,
      formatted: {
        amount: accounting.formatMoney(change.amount ? change.amount : amount, '$', 0),
        payback: payback ? accounting.formatMoney(payback, '$', 0) : null,
        paymentAmount: accounting.formatMoney(Math.ceil(paymentAmount), '$', 0),
        originationFeePercent: parseFloat(option.originationFeePercent).toFixed(2) + '%',
        periodicRate: {
          display: option.paymentFrequency.charAt(0).toUpperCase() + option.paymentFrequency.slice(1) + ' rate',
          value: parseFloat(periodicRate).toFixed(2) + '%'
        }
      }
    }
  }

  function buildSbaOutput (approval, change) {
    const option = findApprovalOptionById(approval.id, change.optionId)
    const periodicRate = option.periodicRate
    const amount = parseInt(change.amount ? change.amount : option.limits.amount.max)
    const paymentAmount = generalFormulas.getPaymentAmount({
      drawAmount: amount,
      periodicRate: periodicRate,
      paymentCount: option.payments,
    })
    const {
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule
    } = approval.calculatorConfig
    const annualRate = generalFormulas.getAnnualRate({
      periodicRate: periodicRate,
      periodsPerYear: option.periodsPerYear,
    })
    const dailyRate = generalFormulas.getDailyRate({
      annualRate: annualRate
    })
    const paymentRate = generalFormulas.getPaymentRate({
      dailyRate: dailyRate,
      daysInPeriod: Number(option.daysInPeriod),
    })
    const calculateScheduleParams = {
      paymentCount: option.payments,
      paymentRate: paymentRate,
      paymentAmount: paymentAmount,
      startBalance: amount,
    }
    const calc = configureCalculator({
      drawFeeAmount,
      disbursement,
      totalCostOfCapital,
      paymentSchedule,
      calculateScheduleParams,
    })
    const drawFee = 0 // no fees are calculated for sba currently
    const finalPaymentAmount = generalFormulas.findFinalPaymentAmount(calc.paymentSchedule)

    const apr = calc.apr(
      amount,
      drawFee ?? 0,
      paymentAmount,
      option.payments,
      option.periodsPerYear,
      finalPaymentAmount
    )
    const interest = generalFormulas.findTotalInterest(
      calc.paymentSchedule, option.term, option.payments, option.term)
    const payback = calc.payback(amount, interest)
    const netFundedAmount = calc.disbursement(amount, drawFee);
    const calculatedPeriodRate = generalFormulas.toFixedNumber((periodicRate || 0.0), 10);

    return {
      optionId: change.optionId,
      approvalId: change.approvalId,
      amount: amount,
      term: option.term,
      paymentFrequency: option.paymentFrequency,
      isAch: false,
      apr: apr,
      annualRate: annualRate,
      drawFee: drawFee,
      numOfPayments: option.payments,
      payments: option.payments,
      paymentAmount: paymentAmount,
      periodsPerYear: option.periodsPerYear,
      periodicRate: calculatedPeriodRate,
      netFundedAmount,
      payback,
      loanType: approval.loanProductCategoryType,
      formatted: {
        amount: accounting.formatMoney(change.amount ? change.amount : amount, '$', 0),
        payback: payback ? accounting.formatMoney(payback, '$', 0) : null,
        paymentAmount: accounting.formatMoney(Math.ceil(paymentAmount), '$', 0),
        periodicRate: {
          display: option.paymentFrequency.charAt(0).toUpperCase() + option.paymentFrequency.slice(1) + ' rate',
          value: parseFloat(periodicRate).toFixed(2) + '%'
        }
      }
    }
  }

function buildOutput(approval, change) {
    if (!approval || !change) {
      return {}
    }
    switch (normalizeLoanType(approval.loanProductCategoryType)) {
      case 'ach':
        return buildAchOutput(approval, change)
      case 'loc':
        return buildLocOutput(approval, change)
      case 'term':
        return buildTermOutput(approval, change)
      case 'sba':
        return buildSbaOutput(approval, change)
      default:
        return {}
    }
  }

  function createContractRequestBody (approval) {
    if (approval.isStaticOffer) {
      return staticOfferContractRequestBody.value(approval)
    }

    const _currentChange = currentChange.value(approval);
    const _output = buildOutput(approval, _currentChange);
    const option = findApprovalOptionById(approval.id, _output.optionId)
    switch (normalizeLoanType(approval.loanProductCategoryType)) {
      case 'ach':
        return {
          dealId: approval.dealId,
          terms: {
            amount: _output.amount,
            term: _output.term,
            termType: 'months',
            factorRate: _output.factorRate,
            paymentFrequency: _output.paymentFrequency,
            paymentCount: _output.payments,
            paymentAmount: _output.paymentAmount,
            payback: _output.payback,
            variablePercentOfRevenue: option.variablePercentOfRevenue,
            expiration: approval.expires,
            netFundedAmount: _output.amount,
            points: points.value,
            commission: _output.commission,
            originationFeePercent: _output.originationFeePercent,
            originationFeeAmount: _output.originationFeeAmount,
          },
          lenderOfferId: null,
          termsType: 'ach',
        }
      case 'loc':
        return {
          dealId: approval.dealId,
          terms: {
            creditLimit: option.limits.drawAmount.max,
            initialDrawAmount: _output.amount,
            term: _output.term,
            termType: 'months',
            paymentFrequency: _output.paymentFrequency,
            paymentAmount: _output.paymentAmount,
            periodicRate: option.periodicRate,
            periodsPerYear: option.periodsPerYear,
            annualInterestRate: _output.annualRate,
            apr: _output.apr,
            drawFeePercent: _output.drawFeePercent,
            drawFeeAmount: _output.drawFee,
            expiration: approval.expires,
            netFundedAmount: _output.amount,
            interestRate: option.periodicRate * option.periodsPerYear,
          },
          termsType: 'loc',
        }
      case 'term':
        return {
          dealId: approval.dealId,
          terms: {
            amount: _output.amount,
            term: _output.term,
            termType: 'months',
            factorRate: _output.factorRate,
            paymentFrequency: _output.paymentFrequency,
            paymentCount: _output.payments,
            paymentAmount: _output.paymentAmount,
            periodicRate: _output.periodicRate,
            periodsPerYear: _output.periodsPerYear,
            annualInterestRate: _output.annualRate,
            netFundedAmount: _output.netFundedAmount,
            apr: _output.apr,
            originationFeePercent: _output.originationFeePercent,
            originationFeeAmount: _output.originationFeeAmount,
            expiration: approval.expires,
            payback: _output.paymentAmount * _output.payments,
            interestRate: _output.periodicRate * _output.periodsPerYear,
          },
          lenderOfferId: null,
          termsType: 'term',
        }
      case 'sba':
        return {
          dealId: approval.dealId,
          terms: {
            amount: _output.amount,
            term: _output.term,
            termType: 'months',
            factorRate: _output.factorRate,
            paymentFrequency: _output.paymentFrequency,
            paymentCount: _output.payments,
            paymentAmount: _output.paymentAmount,
            periodicRate: _output.periodicRate,
            periodsPerYear: _output.periodsPerYear,
            annualInterestRate: _output.annualRate,
            netFundedAmount: _output.netFundedAmount,
            apr: _output.apr,
            // originationFeePercent: _output.originationFeePercent,
            // originationFeeAmount: _output.originationFeeAmount,
            expiration: approval.expires,
            payback: _output.paymentAmount * _output.payments,
            interestRate: _output.periodicRate * _output.periodsPerYear,
          },
          lenderOfferId: null,
          termsType: 'sbastandard',
        }
    }
  }

  async function rejectContractRequest(acceptedDealId) {
    const url = `${env('apiUrl')}/deal/${acceptedDealId}/contract-request/offer-reset`;
    return $axios.delete(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  function clearApprovals() {
    this.setApprovals([])
    unfilteredApprovals.value = []
    changes.value = []

    isSupportedRenewal.value = false
    points.value = null
  }

  return {
    // STATE
    unfilteredApprovals,
    approvals,
    sortBy,
    currentApproval,
    approvalsLoaded,
    points,
    changes,
    contractRequests,
    contractRequestsThroughTasks,
    contractRequestsLoaded,
    showAdjustmentCalculator,
    showChangeIndicators,
    isSupportedRenewal,

    // GETTERS
    getCurrentApproval,
    changesByApprovalId,
    initialChange,
    currentChange,
    hasModifiedApproval,
    approvalById,
    approvalOptionByTermAndFrequency,
    checkoutLinkExpected,
    contractRequestsGetter,
    contractRequestsThroughTasksGetter,
    contractRequestSentForDeal,
    termFrequencyOptions,
    hasContractRequests,
    hasContractRequestsThroughTasks,
    paramBoundariesByType,
    getFactorRate,
    achApprovalOptionCalculatedValues,
    staticOfferContractRequestBody,

    // ACTIONS
    findApprovalOptionById,
    buildLocOutput,
    buildTermOutput,
    buildSbaOutput,
    buildOutput,
    buildAchOutput,
    createContractRequestBody,
    sortApprovals,
    requestContract,
    sendContractRequest,
    submitContractRequest,
    addChange,
    setAchPoints,
    setDefaultChanges,
    sortApprovalsByDefault,
    sortApprovalOptionsByDefaults,
    setApprovals,
    openModal,
    setDefaultApprovalOptions,
    requestApprovals,
    sortApprovalsBy,
    addChangeToApprovals,
    setContractRequests,
    setContractRequestsThroughTasks,
    getContractRequestsByDealId,
    getContractRequestsByBorrowerId,
    getContractRequestsThroughTasks,
    adobeTargetAdjustmentCalculatorTest,
    submitApprovalsTestBorrower,
    resetChanges,
    rejectContractRequest,
    clearApprovals,
  }
})
