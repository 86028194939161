import env from '~/libs/env'
import { localStorageService } from '~/libs/local-storage.service'

/**
 * ApplicationConfigService
 *
 * Facilitates api calls for Application Config models.
 *
 * Requires a store passed so it has access to the correct `$axios` in context
 * for any XHR requests. Helps manage the interface of saving answers between
 * local storage and the actual service.
 */
export class ApplicationConfigService {
    constructor($axios) {
      this.$axios = $axios
    }

    /**
     * Returns ApplicationConfig for given ApplicationConfigEntrypoint identifier.
     * Only includes data for the NEXT route you're visiting
     *
     * @returns ApplicationConfig
     */
    async getByIdentifierAndSlug({ identifier, slug = '', borrowerId, tenantId, direction, anonymousId = null }) {
      const url = borrowerId
        ? `${env('apiUrl')}/application-config/borrower/${borrowerId}/identifier/${identifier}/${slug}`
        : `${env('apiUrl')}/application-config/${identifier}/${slug}`

      let error = null
      const params = borrowerId ? { tenantId, direction } : { tenantId, direction, anonymousId }
      const response = await this.$axios.get(url, { params }).catch(err => error = err)

      return { data: response?.data?.data ?? null, error }
    }

    async getLastInteractedResumeAppUrl({ borrowerId, tenantId, isLogin, anonymousId = null }) {
      if (!borrowerId) {
        return { data: null, error: new Error('borrowerId is required to get the last interacted config') }
      }

      const url = `${env('apiUrl')}/application-config/borrower/${borrowerId}`

      let error = null
      const response = await this.$axios.get(url, { params: { tenantId, anonymousId, isLogin } })
        .catch(err => {
          error = err
        })

      const data = response?.data?.data ?? null
      return { data, error }
    }

    async postApplicationEvent({ identifier, slug = '', eventType, borrowerId = null, anonymousId = null, moduleId = null }) {
      const url = `${env('apiUrl')}/application-config/event/${identifier}/${slug}`
      const body = {
        eventType,
        borrowerId,
        anonymousId,
        moduleId
      }
      let error = null
      const response = await this.$axios.post(url, body)
        .catch(err => {
          error = err
        })

      return { data: response?.data?.data ?? null, error }
    }

    loadAnswersFromStorage() {
      const borrowerAnswers = JSON.parse(localStorageService.getItem('borrowerAnswers')) || {}
      return borrowerAnswers
    }

    async mergeAnswersToStorage(data) {
      const blockedAnswersForStorage = ['contact.ssn']

      const oldBorrowerAnswers = JSON.parse(localStorageService.getItem('borrowerAnswers')) || {}
      const newBorrowerAnswers = Object.entries(data).reduce((acc, [key, value]) => {
        if (blockedAnswersForStorage.includes(key)) {
          return acc
        }

        acc[key] = {
          alias: key,
          value: value
        }
        return acc
      }, {})

      const storeBorrowerAnswers = { ... oldBorrowerAnswers, ... newBorrowerAnswers }
      const storage = JSON.stringify(storeBorrowerAnswers)

      localStorageService.setItem('borrowerAnswers', storage)
    }

    /**
     * @param {{[string]: any}} answers
     * @returns {boolean} successful
     */
    async saveAnswers({ answers, borrowerId }) {
      if (!process.client) {
        log.error('Tried to save answers during server-side render', new Error())
        return false
      }

      const { data } = await this.$axios.post(`${env('apiUrl')}/answers/borrower/${borrowerId}`, answers)
        .then(response => response.data)
        .catch(err => {
          if (err && err.response && err.response.status > 401) {
            log.error('Unable to save answers', Object.keys(answers), err)
          }
          return { data: false }
        })

      return data
    }
}

export default {
  ApplicationConfigService
}
